<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Notification from "@/services/Notification";
    import ModalEditAutomatedNotification from "@/components/modals/notification/modalEditAutomatedNotification";
    import ModalAddAutomatedNotification from "@/components/modals/notification/modalAddAutomatedNotification";
    import Swal from "sweetalert2";

    export default {
        components: {
            Layout,
            PageHeader,
            ModalEditAutomatedNotification,
            ModalAddAutomatedNotification,
        },
        page: {
            title: "Automated Notifications Setup",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                tableData: [],
                tableDataTournament: [],
                title: "Automated Notifications Setup",
                items: [
                    {
                        text: "Notifications",
                    },
                    {
                        text: "Automated Notifications Setup",
                        active: true,
                    },
                ],
                filterInput: {},
                isBusy: false,
                totalRows: 0,
                totalRowsTournament: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "valid_from",
                sortDesc: true,
                isActive: '',
                dealData: [],
                tenantData: [],
                notificationData: [],
                fields: [
                    {
                        key: "type",
                        label: "Type",
                        visible: true,
                    },
                    {
                        key: "title",
                        label: "Title",
                        visible: true,
                    },
                    {
                        key: "message",
                        label: "Message",
                        visible: true,
                    },
                    {
                        key: "active",
                        label: "Active",
                        visible: true,
                    },
                    {
                        key: "Action",
                        label: "Action",
                    }
                ],
                statusOptions: [],
                isBusyAll: false,
                totalRowsAll: 0,
                currentPageAll: 1,
                perPageAll: 10,
                pageOptionsAll: [10, 25, 50, 100],
                filterAll: null,
                filterOnAll: [],
                typeNew: [],
                filterData: false,
                verifyInput: "",
                loading: false
            };
        },

        async created() {
            await this.getAutoNotifications();
        },
        methods: {

            async getAutoNotifications() {
                try {
                    this.filterData = true;
                    this.isBusy = true;

                    // Fetch auto notifications
                    const response = await Notification.getAutoNotifications();
                    this.tableData = response.data.data;
                    this.isActive = response.data.data.some(item => item.active === 1);

                    const autoResponse = await Notification.getTypeNotification();

                    const existingTypes = autoResponse.data.data.map(item => item.type);
                    const usedTypes = new Set(response.data.data.map(item => item.type));


                    this.typeNew = existingTypes.filter(type => !usedTypes.has(type));

                    this.type = this.typeNew.map(type => ({
                        value: type,
                        text: type
                    }));

                    this.totalRows = this.tableData.length;
                    this.isBusy = false;
                } catch (error) {
                    this.isBusy = false;
                    this.error = error.response?.data?.error || "";
                    this.tableData = [];
                    this.totalRows = 0;
                }
            },

            callModalEditTeamDeal(data) {
                this.$bvModal.show("edit_automated_notification");
                this.notificationData = data
            },

            callModalAddTenantSetup(data) {
                this.$bvModal.show("add_automated_notification");
                this.notificationData = data
            },

            async updateAutomatedNotification(data) {
                this.tryingToSubmit = true;
                this.submitted = true;

                let formData = new FormData();
                formData.append('active', data.active);
                formData.append('type', data.type);
                formData.append('_method', 'PATCH');

                try {
                    const response = await Notification.updateAutomatedNotification(formData, data.type);
                    if (response.error) {
                        this.failedmsg(response.error);
                    } else {
                        // Ensure active is treated as a number
                        this.successmsg(response.data.message, data.type, Number(data.active));
                    }
                } catch (error) {
                    this.error = error.response?.data?.error || "An error occurred";
                    this.failedmsg(this.error);
                } finally {
                    this.tryingToSubmit = false;
                    this.showLoader = false;
                }
            },

            successmsg(message, type, active) {
                const status = Number(active) === 1 ? "Activated" : "Deactivated";
                Swal.fire({
                    title: "Success",
                    html: `${message}<br>${type} is ${status}`,
                    icon: "success",
                });
            }

        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" :fields="fields"/>
        <div class="row">
            <div class="col-12">
                <div class="card" v-show="filterData">
                    <div class="card-body">
                        <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" v-if="this.typeNew.length"
                           @click="callModalAddTenantSetup">
                            <i class="mdi mdi-plus me-2"></i>Create Automated Notification
                        </a>

                        <div class="row mt-4">
                            <div class="col d-inline-flex align-items-center">
                                <h4 class="card-title m-0">Total Automated Notificatons Setup: {{ totalRows }}</h4>
                            </div>
                        </div>
                        <div class="row mt-4" v-show="filterData">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="deals-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>

                        <!-- Table -->
                        <div class="table-responsive mb-0 font-size-12" v-show="filterData">
                            <b-table
                                    :items="tableData"
                                    :busy="isBusy"
                                    :fields="fields"
                                    :visible="fields.visible"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                            >
                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Automated Notification" @click="callModalEditTeamDeal(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template v-slot:cell(active)="data">
                                    <div class="col-4 form-check form-switch form-switch-lg">
                                        <input
                                                v-model="data.item.active"
                                                type="checkbox"
                                                class="form-check-input"
                                                id="switch1"
                                                true-value="1"
                                                false-value="0"
                                                @change="updateAutomatedNotification(data.item)"
                                                :style="{ left: '0px' }"
                                        />
                                    </div>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <!--Table end -->

                        <div class="row" v-show="filterData">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <ModalEditAutomatedNotification :notificationData="notificationData" @onRefresh="getAutoNotifications()"></ModalEditAutomatedNotification>
        <ModalAddAutomatedNotification :notificationData="notificationData" @onRefresh="getAutoNotifications()"></ModalAddAutomatedNotification>
        <!-- /MODALS -->
    </Layout>
</template>

