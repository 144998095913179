<script>
    import { required, maxLength } from "vuelidate/lib/validators";
    import validationMessages from "@/components/validations";
    import Notification from "@/services/Notification";
    import Swal from "sweetalert2";

    export default {
        components: { validationMessages },

        data() {
            return {
                selectedType: "",
                title: "",
                message: "",
                type:[],
                showModal: false,
                submitted: false,
                tryingToSubmit: false,
                showLoader: false,
                csrf_token: localStorage.getItem("csrf_token"),
                error: null,
            };
        },

        validations: {
            selectedType: {
                required,
                maxLength: maxLength(16),
            },
            title: {
                maxLength: maxLength(256),
            },
            message: {
                maxLength: maxLength(256),
            },
           
        },

        methods: {
            closeModal() {
                this.showModal = false;
                this.resetForm();
            },

            async getTypeNotification() {
                this.showLoader = true;
                try {
                    const response = await Notification.getTypeNotification();
                    const allTypes = response.data.data.map(item => ({
                        value: item.type,
                        text: item.type
                    }));

                    // Fetch existing types from getAutoNotifications()
                    const autoResponse = await Notification.getAutoNotifications();
                    const existingTypes = new Set(autoResponse.data.data.map(item => item.type));

                    // Filter out types that already exist in auto notifications
                    this.type = allTypes.filter(item => !existingTypes.has(item.value));
                } catch (error) {
                    console.error("Error fetching types:", error);
                } finally {
                    this.showLoader = false;
                }
            },

            async createAutomatedNotification() {
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                }

                let formData = new FormData();
                formData.append("type", this.selectedType);
                formData.append("title", this.title);
                formData.append("message", this.message);
                formData.append("csrf_token", this.csrf_token);

                try {
                    const response = await Notification.createAutomatedNotification(formData);
                    if (response.error) {
                        this.failedmsg(response.error);
                    } else {
                        this.successmsg(response.message);
                        this.closeModal();
                        this.refreshData();
                    }
                } catch (error) {
                    this.error = error.response?.data?.error || "An error occurred";
                    this.failedmsg(this.error);
                } finally {
                    this.tryingToSubmit = false;
                    this.refreshData();
                }
            },

            successmsg(message) {
                Swal.fire("Success", message, "success");
            },

            failedmsg(message) {
                Swal.fire("Error", message, "error");
            },

            refreshData() {
                this.$emit("onRefresh");
            },

            resetForm() {
                this.selectedType = "";
                this.title = "";
                this.message = "";
                this.$v.$reset();
            },

            modalShown(){
                this.getTypeNotification()
            }
        },
    };
</script>


<template>
    <b-modal  @shown="modalShown" id="add_automated_notification" size="l" v-model="showModal" @hidden="resetForm" title="Automated Notification" title-class="font-18">
        <form @submit.prevent="createAutomatedNotification" v-if="!showLoader">
            <div class="row">
                <div v-if="$v.$error" class="text-danger">Complete all fields</div>
                <b-card-text>
                    <div class="row">
                        <div class="col-sm-12">
                            <b-form-group label="Type" label-for="formrow-selectedType-input" class="mb-3">
                                <b-form-select
                                        v-model="selectedType"
                                        :options="type"
                                        :class="{ 'is-invalid': submitted && $v.selectedType.$error }"
                                ></b-form-select>
                                <validationMessages v-if="submitted" :fieldName="'Type'" :validationName="$v.selectedType"></validationMessages>
                            </b-form-group>

                            <b-form-group label="Title" label-for="formrow-title-input" class="mb-3">
                                <b-form-input v-model.trim="title" ></b-form-input>
                            </b-form-group>
                            
                            <b-form-group label="Message" label-for="formrow-message-input" class="mb-3">
                                <b-form-input v-model.trim="message" ></b-form-input>
                            </b-form-group>

                            <input type="hidden" name="csrf_token" v-model="csrf_token">
                        </div>
                    </div>
                </b-card-text>
            </div>
        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createAutomatedNotification" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
            </b-button>
        </template>
    </b-modal>
</template>
